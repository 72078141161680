<template>
  <v-container class="py-6">
    <ShopCommonTopBar />

    <v-row>
      <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto">
        <v-form lazy-validation @submit.prevent="register()">
          <v-card elevation="0">
            <v-card-title style="padding: 16px 0">
              <span class="text-h6 fw-600">
                {{ $t('setting_your_password') }}
              </span>
            </v-card-title>
            <v-card-text style="padding: 16px 0">
              <div class="mb-4">
                <div class="mb-1 fs-13 fw-500 card-text">
                  {{ $t('full_name') }}
                </div>
                <v-text-field
                  :placeholder="$t('full_name')"
                  type="text"
                  v-model="form.name"
                  :error-messages="nameErrors"
                  @blur="$v.form.name.$touch()"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>
              </div>

              <div class="mb-4">
                <div class="mb-1 fs-13 fw-500 card-text">
                  {{ $t('password') }}
                </div>
                <v-text-field
                  placeholder="* * * * * * * *"
                  v-model="form.password"
                  :error-messages="passwordErrors"
                  @blur="$v.form.password.$touch()"
                  type="password"
                  class="input-group--focused"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>
              </div>
              <div class="mb-4">
                <div class="mb-1 fs-13 fw-500 card-text">
                  {{ $t('confirm_password') }}
                </div>
                <v-text-field
                  placeholder="* * * * * * * *"
                  v-model="form.confirmPassword"
                  :error-messages="confirmPasswordErrors"
                  @blur="$v.form.confirmPassword.$touch()"
                  type="password"
                  class="input-group--focused"
                  hide-details="auto"
                  required
                  outlined
                ></v-text-field>
              </div>

              <!-- 推荐码 -->
              <div class="mb-4" v-if="isShowReferral">
                <div class="mb-1 fs-13 fw-500 card-text">
                  {{ $t('recommender') }}
                </div>
                <v-text-field
                  :placeholder="$t('please_input_recommender')"
                  v-model="form.referral_code"
                  :error-messages="recommenderErrors"
                  @blur="$v.form.referral_code.$touch()"
                  type="text"
                  class="input-group--focused"
                  hide-details="auto"
                  :required="referralRequiredSwitch"
                  outlined
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <v-row>
            <v-col cols="5">
              <v-btn
                x-large
                class="px-12 mb-4 w-100 mt-5"
                elevation="0"
                color="#E7E7E7"
                @click.stop="routeBack"
                style="color: #a8a8a8"
              >
                <i
                  class="la la-arrow-left fs-20"
                  style="position: absolute; left: -40px"
                ></i>
                {{ $t('back') }}
              </v-btn>
            </v-col>

            <v-col>
              <v-btn
                x-large
                class="px-12 mb-4 w-100 mt-5"
                elevation="0"
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="loading"
              >
                {{ $t('next') }}

                <i
                  class="la la-arrow-right fs-20"
                  style="position: absolute; right: -40px"
                ></i>
              </v-btn>
            </v-col>
          </v-row>

          <div class="mb-4 text-center" style="color: #a8a8a8">
            {{ $t('by_signing_up_you_agree_to_our') }}
            <router-link
              :to="{
                name: 'CustomPage',
                params: { pageSlug: 'terms' }
              }"
              class="primary--text text-decoration-underline"
            >
              {{ $t('terms_and_conditions') }}
            </router-link>
          </div>

          <div class="text-center text-decoration-underline fs-14">
            <a :href="contactUrl" target="_blank" class="text-reset opacity-60">
              <!-- <i class="la la-phone" /> -->
              <span>{{ $t('help_line_link') }}</span>
              <!-- <span>{{ data.helpline }}</span> -->
            </a>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  requiredIf
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { VueTelInput } from 'vue-tel-input'

// component
// 图片拖拽验证组件
import DragVerifyImgChip from '@/components/auth/DragVerifyImgChip'
import ShopCommonTopBar from '@/components/shop/ShopCommonTopBar'

export default {
  name: 'ShopPersonalInformation',

  components: {
    ShopCommonTopBar,
    VueTelInput,
    DragVerifyImgChip
  },

  data() {
    return {
      loading: false,

      form: {
        name: '',
        phone: '',
        email: '',
        password: '',
        confirmPassword: '',
        invalidPhone: false,
        showInvalidPhone: false,
        verificationCode: '',
        referral_code: ''
      },

      isShowPhone: false,

      isShowEmail: true,

      referralRequiredSwitch: false,
      isShowReferral: false
    }
  },

  computed: {
    ...mapGetters('app', [
      'generalSettings',
      'availableCountries',
      'getShopRegisterType',
      'emailVerification',
      'h5Url',
      'contactUrl'
    ]),
    ...mapGetters('auth', ['shopName', 'shopPhone', 'shopAddress']),

    nameErrors() {
      const errors = []
      if (!this.$v.form.name.$dirty) return errors
      !this.$v.form.name.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.password.minLength &&
        errors.push(this.$i18n.t('password_must_be_minimum_6_characters'))
      return errors
    },
    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirmPassword.$dirty) return errors
      !this.$v.form.confirmPassword.required &&
        errors.push(this.$i18n.t('this_field_is_required'))
      !this.$v.form.confirmPassword.sameAsPassword &&
        errors.push(this.$i18n.t('password_and_confirm_password_should_match'))
      return errors
    },

    recommenderErrors() {
      const errors = []
      if (!this.referralRequiredSwitch || this.form.referral_code) return
      if (!this.$v.form.referral_code.$dirty) return errors
      !this.$v.form.referral_code.required &&
        this.referralRequiredSwitch &&
        this.isShowReferral &&
        errors.push(this.$i18n.t('this_field_is_required'))
      return errors
    }
  },

  validations: {
    form: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
      referral_code: {}
    }
  },

  methods: {
    ...mapActions('auth', ['login']),
    ...mapMutations('cart', ['removeTempUserId']),
    ...mapMutations('auth', ['updateChatWindow', 'showLoginDialog']),

    routeBack() {
      this.$router.back()
    },

    async register() {
      this.$v.form.$touch()

      if (
        this.$v.form.name.$error ||
        this.$v.form.password.$error ||
        this.$v.form.confirmPassword.$error
      ) {
        return
      }

      this.form.register_type = 'seller'

      // 如果 url 有邀请码，把邀请码带上
      if (this.referralCode) {
        this.form.referral_code = this.referralCode
      }

      this.loading = true

      // 如果是电话号码注册，则带上区号
      const params = { ...this.form }

      if (this.isShowPhone) {
        params.phone = this.form.phone
        params.email = ''
      } else {
        params.phone = ''
      }

      const {
        data: { success, message }
      } = await this.call_api('post', 'register', params)

      // 注册成功，重定向到商家登录页面
      if (success) {
        this.$router.push({ name: 'ShopRegisterEnd' })
      } else {
        this.snack({
          message: message,
          color: 'red'
        })
      }
      this.loading = false
    },

    resetForm() {
      this.form.name = ''
      this.form.password = ''
      this.form.confirmPassword = ''
      this.form.referral_code = ''
      this.form.invalidPhone = false
      this.form.showInvalidPhone = false
    },

    // 推荐码开关
    async getReferralSwitch() {
      const { data } = await this.call_api(
        'get',
        'get-business-settings/referral_switch'
      )

      this.isShowReferral = data.data.referral_switch === 'on'

      this.form.referral_code = this.referralCode
    },

    // 推荐码是否必填
    async getReferralRequiredSwitch() {
      const { data } = await this.call_api(
        'get',
        'get-business-settings/referral_required_switch'
      )

      this.referralRequiredSwitch = data.data.referral_required_switch === 'on'
      console.log('referral_required_switch  ', data)
    }
  },

  created() {
    const { referral_code, type, verificationCode, value } = this.$route.query

    if (type === 'email') {
      this.isShowEmail = true
      this.form.email = value
    } else if (type === 'phone') {
      this.isShowPhone = true
      this.form.phone = value
    }

    this.form.verificationCode = verificationCode
    this.referralCode = referral_code

    this.getReferralSwitch()
    this.getReferralRequiredSwitch()
  }
}
</script>

<style lang="scss" scoped>
.dialog-container {
  padding: 30px;
}

.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px;
}

::v-deep .drag-verify-container {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.card-text {
  color: #000;
}
</style>
